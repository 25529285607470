/**
 * InactiveCard
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { EXODE_SUPPORT_TG_URL } from '@/root/src/env';

import { observer, PlatformStore } from '@/store/platform';

import { Link, Rbac } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { Permission } from '@/codegen/graphql';

import { Button, Text, Title } from '@exode.ru/vkui';
import { Icon24LifebuoyOutline, Icon24PaymentCardClockOutline } from '@vkontakte/icons';


const InactiveCard = observer(() => {

    const { t } = useI18n('components.School.InactiveCard');

    return (
        <div className="flex flex-col items-center d:mb-2">
            <Title level="2" weight="3">
                {t('schoolIsNotActive')}
            </Title>

            <Rbac.Wrapper permissions={[ Permission.SchoolManageSettings ]}>
                <Rbac.Allowed>
                    <Text className="text-center text-muted mt-3 mb-8">
                        {t('requiredToPay')}
                    </Text>

                    <Link pushPage={{ id: '/manage/school/subscription' }}>
                        <Button stretched size="l" mode="commerce" before={<Icon24PaymentCardClockOutline/>}>
                            {t('goToPayment')}
                        </Button>
                    </Link>
                </Rbac.Allowed>

                <Rbac.Forbidden>
                    <Text className="text-center text-muted mt-3 mb-8">
                        {t('writeToTheSchoolAdministrator')}
                    </Text>

                    <Link toOuter={(
                        PlatformStore.COMMON?.organization.info.messengerSupportUrl
                        || EXODE_SUPPORT_TG_URL
                    )}>
                        <Button stretched size="l" mode="secondary" before={<Icon24LifebuoyOutline/>}>
                            {(PlatformStore.COMMON?.organization.info.messengerSupportUrl
                                    ? t('contactAdmin')
                                    : t('contactSupport')
                            )}
                        </Button>
                    </Link>
                </Rbac.Forbidden>
            </Rbac.Wrapper>
        </div>
    );
});


export { InactiveCard };
